/**         **\
++ Frontpage ++
\**         **/

/*.product_element{
  .product_image{
    height:100%;
    background-repeat:no-repeat;
  }
}*/

.manuals {
  max-height: 382px;
  display: initial;
  img {
    height: auto;
    width: 100%;
    background-repeat: no-repeat;
  }
}
.about_img {
  max-height: 500px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;

  img {
    width: auto;
    max-height: 500px;
    height: 100%;
    @media #{$medium-down} {
      width:100%;
      height:auto;
    }
  }
}
.globe {
  background: url(#{$imgpath}euradrives-globe.svg);
  height: 178px;
  width: 192px;
  background-size: cover;
  background-repeat: no-repeat;
}
/**         **\
++   Footer ++
\**         **/

#footer_logo {
  background: url(../../assets/img/logo.svg) no-repeat;
  background-size: contain;
  background-position: bottom;
  max-height: 60px;
  max-width: 405px;
  height: 100%;
}
#fb_icon {
  background: url(#{$imgpath}fb_icon.svg);
  background-size: cover;
  height: 20px;
  width: 20px;
}
#vimeo_icon {
  background: url(#{$imgpath}vimeo_icon.svg);
  background-size: cover;
  height: 20px;
  width: 20px;
}
/**         **\
++  Product  ++
\**         **/

.product_nav {
  #application_btn, #inverter_btn, #motor_btn, #controller_btn {
    background-image: url(#{$imgpath}icons/application_icon.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  #inverter_btn {
    background-image: url(#{$imgpath}icons/EP66Form.svg);
  }
  #motor_btn {
    background-image: url(#{$imgpath}icons/EVPMForm.svg);
  }
  #controller_btn {
    background-image: url(#{$imgpath}icons/PumpMasterForm.svg);
  }
}
/**                 **\
++  Single Product  ++
\**                 **/

.contact_social {
  .social_icon {
    height: 3.5rem;
    width: 3.5rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    &.pin {
      background-image: url(#{$imgpath}icons/pin.svg);
    }
    &.facebook {
      background-image: url(#{$imgpath}icons/facebook-round.svg);
    }
    &.vimeo {
      background-image: url(#{$imgpath}icons/vimeo-round.svg);
    }

  }

}
//.install_icon { background-image: url(#{$imgpath}application/install_app_icon.svg); }
//.sms_icon { background-image: url(#{$imgpath}application/sms_app_icon.svg); }
//.menu_icon { background-image: url(#{$imgpath}application/menu_app_icon.svg); }
//.smart_icon { background-image: url(#{$imgpath}application/smart_app_icon.svg); }
//.time_icon { background-image: url(#{$imgpath}application/time_app_icon.svg); }
//.sec_icon { background-image: url(#{$imgpath}application/sec_app_icon.svg); }
//.warn_icon { background-image: url(#{$imgpath}application/warn_app_icon.svg); }
//.bt_icon { background-image: url(#{$imgpath}application/bt_app_icon.svg); }

img {
  &.centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.image-label {
  background-color: white;
  color: black;
  width: 100%;
  text-align: center;

  &.dark {
    background-color: $primaryDarkColor5;
    color: white
  }
  &.dark-alt {
    background-color: $primaryDarkColor5;
    color: black;
  }

  &.size-large {
    font-size: 1.5rem;
    font-weight: bold;
    padding: 1rem 0 ;
  }
  &.size-medium {
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0.75rem 0 ;
  }
  &.size-small {
    font-size: 1rem;
    padding: 1rem 0 ;
    margin-bottom: 10px;
  }
  &.as-caps {
    text-transform: uppercase;
  }
}

.legend {

  &-el {
    text-align: center;
    margin-bottom: 10px;
    .legend-caption {
      background-color: #D0D5D9;
      color: black;
      width: 70%;
      margin-right: 2%;
      display: inline-block;
      height: 50px;
      line-height: 50px;
      vertical-align: top;
    }
    .legend-tag {
      width: 15%;
      background-color: black;
      color: white;
      font-size: 36px;
      text-align: center;
      display: inline-block;
      font-weight: bold;
    }
  }
}