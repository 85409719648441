//#application_wrapper {
#application_display {
  .container {
    background-color: $primaryColor9;
    h1 {
      margin-bottom: 28px;
      //text-align: left;

      @include headerStyleSmall;
      .header_sm {
        font-size: 15px;
        @include din2014light;

        
        @media #{$small-down}{
            display: inline-block;
            padding-left: 60px;
            left: 0;
            position: relative;
            line-height: 20px;
            margin-top: 20px;

        }
      }

    }
    img {
      background-color: $secondaryColor2;
      max-height: 320px;
    }

  }

  .application_text {
    background-color: white;
    padding: 50px 30px;

    h2 {
      text-align: left;
      @include headerStyling(25px, 36px);
      font-size: 20px;
      line-height: 24px;
      color: $primaryDarkColor2;
    }

    p {
        margin-top: 10px;
        color: $textLight2;
        font-size: 14px;
        line-height: 16.8px;
    }
  }
  .row {
    margin-bottom: 20px;
  }
  .application_img {
    background-color: #c9c9c9;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  img {
    width: 100%;
  }

  .single_img_left .hidden-md{
      z-index: 1;

      .application_img{
          margin-bottom: 20px;
      }
  }
  .single_img_right .hidden-md{
      z-index: 1;

      .application_img{
          margin-bottom: 20px;
      }
  }

  @media #{$medium-up}{
    .application_img {
      min-height: 320px;
    }
    .no-padding-left {
      padding-left: 0;
    }

    .no-padding-right {
      padding-right: 0;
    }
  }
}
/* Placeholder style until sample images can be tested */
//}