.frontpage {
  footer {
    margin-bottom: -25px;
    position: relative;
    background-color: white;
  }
}
:not(.frontpage) {
  footer {
    //margin-bottom: -$headerHeight;
  }
}
footer {
  color: $primaryColor5;
  font-size: 13px;

  #footer_container {
    background-color: $primaryDarkColor2;
    padding: 40px 70px 50px;
    min-height:225px;

    .row {
      position: relative;
    }
    .frame {
      min-height: 160px;
    }

    .footer_bottom {
      position: absolute;
      bottom:0;

      &.copyright {
        text-align: center;
        letter-spacing: 1.1px;
        margin: auto;
        left: 0;
        right: 0;
      }

      &.contact {
        right:0;
      }
      
      @media #{$medium-down} {
        position: relative;
      }
    }

    /*
        letter-spacing: 0.05em;
    line-height: 20px;
    */

    #footer_logo {
      position: absolute;
      bottom:0;
      width: 67%;
      min-height: 34px;
    }

    #contact_info {
      table {
        @include din2014light;

        td {
          padding: 5px 5px 0;
          min-width: 90px;
          vertical-align: bottom;
        }
      }

    }
    #footer_copyright {
    }
    .social_icons {
      text-align: center;
      div {
        display: inline-block;
      }

      a {
        display: inline-block;
        svg {
          width: 43px !important;
          height: 43px !important;
          margin-right: 18px;
        }
        #facebook_icon{
          .fil0 {fill: #283890}
        }
        #xing_icon{
          .fil0 {fill: #168086}
        }
        #linkedin_icon{
          .fil0 {fill: #1074bc}
        }
        #youtube_icon{
          .fil0 {fill: #be1d2c}
        }

        .fil1 {fill: #fff; transition: fill 0.5s;}

        &:hover {
          text-decoration: none;
          .fil0 {
            fill: $gradientColor1
          }
        }
      }
    }

    .foot_nav {

      .row {
        padding: 2rem 0;

        &.top-row {
          border-bottom: white 1px solid;
          ul {
            width: 100%;
            position: relative;
          }
          li {
            display: inline-block;
            width: 18%;
            a {
              color: white;
              padding-right: 2rem;
            }
            &:last-child {
              padding-right: 0;
              width: 6%;
              text-align: right;
              a {
                width: 100%;
                text-align: right;
              }

            }
          }
        }
        &.middle-row {
          border-bottom: $primaryColor1 1px solid;
          color: $primaryColor5;
        }
        &.bottom-row {
          border-bottom: $primaryColor1 1px solid;
          li {
            padding-bottom: 2rem;
            a {
              color: $primaryColor5;
            }
          }
        }

        a {
          &:hover {
            text-decoration: none;
          }

          &.active {
            //padding-bottom: 18px;
            //width:70%;
            //position: absolute;
            border-bottom: 3px solid transparent;
            @include borderGradient()
          }
        }
      }

      ul {
        list-style: none;
        padding: 0;
      }
    }

    .foot_nav {
      bottom: 0;
      right: 10px;
      padding:15px 0 25px;

      a {
        padding-right:10px;
        color: $primaryDarkColor4;
        float: left;
        clear: both;
        letter-spacing: 0.05em;
        line-height: 20px;

        &:hover {
          color: $primaryColor5;
        }
      }
    }

    .footer-top-btn {
      position: absolute;
      cursor: pointer;
      text-align: right;
      top:0;
      right: 5px;
      margin-top:-5px;
      @media #{$medium-down} {
        right: 0;

      }

      span {
        position: absolute;
        top: 17px;
        right: 57px;
      }
      svg {
        height:50px !important;
        width:50px !important;
        .fil1 {fill:#fff}
        .fil0 {fill:$primaryDarkColor4}
      }

      &:hover {
        .fil0{fill:$gradientColor1}
      }
    }
  }

  @media #{$medium-down}{
    #footer_container {
      padding: 45px 40px 10px;

      .frame {
        min-height: 140px;
      }

      #footer_logo {
        margin-bottom: 10px;
        max-width: 58%;
      }

      .foot_nav {
        display: none;
      }
      .row.top-row {
        ul {
          li {
            display: block;
          }
        }
      }
    }
  }
  @media #{$small-down} {
    #footer_container {
      .frame {
        min-height: 100px;
      }
      #footer_logo {
        margin-bottom: 10px;
        max-width: 58%;

        @media #{$xsmall-only} {
          max-width: 58%;


        }
      }
    }
  }
}