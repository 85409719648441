$job-font-size:18px;

//#jobs_wrapper {
  #jobs_banner {
    .container {
      //background: url(#{$imgpath}jobs_banner.png) no-repeat;
      background-size: cover;
      height: 400px;
      //width:100%;
    }
  }

  #jobs_content {
    .container {
      background-color: $primaryColor9;

      h1 {
        margin-bottom:40px;
        position: relative;
        font-size: 50px;

        .header_sm {
          @include din2014light();
          position: relative;
          left:50px;
          top:0;
          &:before {
            content: '';
            border-bottom:3px solid $primaryDarkColor1;
            width: 25px;
            position: absolute;
            left: -45px;
            top: 8px;
          }
        }
      }
      .job_single_container {
        background-color: white;
        padding:25px 30px 48px;
        margin-bottom: 20px;

        h2{
          &.job_title {
            margin-bottom: 40px;
            position: relative;
            font-size: 20px;

            &:after {
              content:'';
              background: url(#{$imgpath}icons/job_round.svg) no-repeat;
              height:30px;
              width:30px;
              background-size: cover;
              position:absolute;
              right:0;
              top:0;

            }

            .header_sm {
              &:before {
                content:'';
                width:25px;
                border-bottom:2px solid $primaryDarkColor2;
                position: absolute;
                top:6rem;

              }
            }
          }
        }

        .job_text {
          padding-bottom:60px;
          font-size: 14px;
          color:$textLight2;
        }

        .job_contact {
          @include din2014light;
          font-size: $job-font-size;
          font-weight:400;

          hr {
            border-bottom: 2px solid $primaryDarkColor2;

          }

          .col-sm-12{

            @media #{$small-down}{
              margin-bottom: 20px;
            }

          }

          .contact_green {
            color:$gradientColor1;
          }
          .contact_grey {
            color:$textLight2;
          }
        }
      }
    }
  }
//}