
$base-fontsize: 16px;

/* main settings */
$imgpath: "../../assets/img/";
$pagingNumberSize: 25px;
$defaultBottomMargin: 60px;
$defaultBorderRadius: 3px;
$headerBarHeight: 40px;
$headerHeight: 90px;
$headerMobileHeight: 54px;
$headerBarHeightMobile: 60px;
$mainNavigationHeight: 90px;
$mainNavigationHeightMobile: 60px;

$sectionHeaderLgFntSize: 50px;

/* margins */
$margin-small-down: 20px; // spacing between responsive col elements when stacked
$font-size-base: 10px;

/* bootstrap settings */
$grid-gutter-width: 20px;
$screen-xs:   480px;
$screen-sm:   768px;
$screen-md:   992px;
$screen-lg:   1200px;

/* Extra Breakpoints */
$screen-xlg: 1700px;
$screen-xlg-min: $screen-xlg; //1700px;

$screen-xxlg: 1920px;
$screen-xxlg-min: $screen-xxlg; //1920px;

//$container-sm: 64rem;
//$container-md: 76.8rem;

// Large screen / wide desktop
$container-xlarge-desktop:      (1420px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-xlg:                 $container-xlarge-desktop !default;

// Large screen / wide desktop
$container-xxlarge-desktop:      (1420px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-xxlg:                 $container-xxlarge-desktop !default;


$xsmall-only:  "(max-width: #{$screen-xs})";
$xsmall-up:    "(min-width: #{$screen-xs})";

$small-down:  "(max-width: #{$screen-sm})";
$small-up:    "(min-width: #{$screen-sm})";

$medium-down: "(max-width: #{$screen-md })";
$medium-only: "(min-width: #{$screen-md}) and (max-width: #{$screen-lg})";
$medium-up:   "(min-width: #{$screen-md})";

$large-down:  "(max-width: #{$screen-lg})";
$large-only:  "(min-width: #{$screen-lg})and (max-width: #{$screen-xlg})";
$large-up:    "(min-width: #{$screen-lg})";

$x-large-down:  "(max-width: #{$screen-xlg})";
$x-large-only:  "(min-width: #{$screen-xlg})and (max-width: #{$screen-xxlg})";
$x-large-up:    "(min-width: #{$screen-xlg})";

$xx-large-down:  "(max-width: #{$screen-xxlg})";
$xx-large-only:  "(min-width: #{$screen-xxlg})";
$xx-large-up:    "(min-width: #{$screen-xxlg})";
