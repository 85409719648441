.section.media_video {
  .container {
    //@include headerStyleSmall;
    background-color: $primaryColor9;
    position: relative;

    .video_container {
      background-color: $secondaryColor2;
      position: relative;
      width: 80%;
      padding-bottom:44.5%;
      height:auto;
      margin: auto;
      transition: all 0.5s ease;
      .video_stretch{
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;

        & >img {
          width:100%;
          height:auto;
        }
        .video_button {
          //height: 100%;
          //width: 100%;
          //display: table-cell;
          //vertical-align: middle;
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -35px;
            margin-left: -52px;
            cursor: pointer;
          }
        }
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      @media #{$medium-down} {
        min-height: 300px;
      }
      @media #{$small-down} {
        min-height: 250px;
      }

      &.vid_loaded {
        background-color: transparent;
      }
    }
  }
}