

@mixin make-grid-columns($i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}, .col-xlg-#{$i}, .col-xxlg-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xs-#{$i}, .col-is-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}, .col-xlg-#{$i}, .col-xxlg-#{$i}";
  }
  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left: ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
  }
}

@include make-grid-columns;

@media (min-width: $screen-xlg-min) {
  @include make-grid(xlg);
}
@media (min-width: $screen-xxlg-min) {
  @include make-grid(xxlg);
}


.container {
  @media (min-width: $screen-xlg-min) {
    width: $container-xlg;
  }
}
.container {
  @media (min-width: $screen-xxlg-min) {
    width: $container-xxlg;
  }
}