@mixin shiftCenter() {
  display: block;
  position: relative;
  left: 50%;
  @include translate(-50%, 0);
}
@mixin headerStyling($line-width:35px, $align-top:22px) {
  text-align: left;
  margin-bottom: 50px;
  &:after {
    content: '';
    width: $line-width;
    border-bottom: 2px solid $primaryDarkColor2;
    top: $align-top;
    display: block;
    position: relative;
    //@include shiftCenter();
  }
}
@mixin headerStyleSmall {

  h1,h2,h3 {
    .header_sm {
      position: relative;
      left: 60px;
      @include din2014light();
      font-size: 0.5em;
      color: $textLight1;
      top:-24px;
      &:before {
        content: '';
        width: 35px;
        border-bottom: 2px solid $primaryDarkColor2;
        display: block;
        left: -60px;
        top: 40px;
        position: relative;
      }
      &.top-30 {
        top:30px;
      }
    }
    @media #{$small-down} {
      .header_sm {
        top: -6px;
        &:before {
          top:22px;
        }
      }
    }
  }
  h2 {
    .header_sm {
      top:-10px;
      &:before {
        // top:28px;
      }
    }
  }


}

@mixin borderGradient {

  ///border-bottom: 2px solid transparent;
  -moz-border-image: -moz-linear-gradient(left, #158085 0%, #99CCAA 100%);
  -webkit-border-image: -webkit-linear-gradient(left, #158085 0%, #99CCAA 100%);
  border-image: linear-gradient(to right, #158085 0%, #99CCAA 100%);
  border-image-slice: 1;

}

@mixin solidBorderGradient ($background-color:white, $border-radius: 5px) {

   &:before {
     //content:"";
     //position: absolute;
     //top: 0; bottom: 0; left: 0; right: 0;
     background-color: rgba(0,0,0,0);
     background-image: url(#{$imgpath}line-btn.svg) ;
     background-repeat: no-repeat;
     background-size: cover;

  }

}

@mixin fontSize($size) {
    font-size: $size; //Fallback in px
    font-size: calculateRem($size);
}

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: ($height / $width) * 100%;
    }
    > div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }