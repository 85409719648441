/** DIN 2014 fonts **/
@mixin din2014 {
  font-family: "din-2014", sans-serif;
  font-weight:400;
}
@mixin din2014bold {
  font-family : "din-2014", sans-serif;
  font-weight:700;
}
@mixin din2014extrabold {
  font-family : "din-2014", sans-serif;
  font-weight:800;
}
@mixin din2014light {
  font-family : "din-2014", sans-serif;
  font-weight:300;
}
@mixin din2014extralight {
  font-family : "din-2014", sans-serif;
  font-weight:200;
}


@mixin firaSans {
  font-family: "fira-sans", sans-serif;
  font-weight:400;
}
@mixin firaSansBold {
  font-family: "fira-sans", sans-serif;
  font-weight:700;
}
@mixin firaSansItalic {
  font-family: "fira-sans", sans-serif;
  font-weight:400;
  font-style: italic;
}
@mixin firaSansLight {
  font-family: "fira-sans", sans-serif;
  font-weight:300;
}
@mixin firaSansSemiBold {
  font-family: "fira-sans", sans-serif;
  font-weight:600;
}