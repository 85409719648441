.submenu {
  display:none;
  position: relative;
  top: $headerHeight - 34px;
  @include gradient1();

  opacity: 0.9;

  &:before {
    content:'';
    width:100%;
    top:40px;
    position: absolute;
    border-bottom: rgba(255,255,255, 0.5) 2px solid;
  }

  > div.page {
    display: none;

    > ul {
      text-align: center;
      position: relative;
      @media #{$medium-up} {
        left:20px;
      }
      @media #{$large-up} {
        left:-60px;
      }
      @media #{$x-large-up} {
        left:85px;
      }

    }

    &.active {
      display: block;
    }
  }
  li.submenu_item {
    color: $textWhite;
    text-align: left;
    display: inline-block;
    vertical-align: top;
    padding: 10px 30px;

    .submenu_head {
      @include din2014bold;
      font-size: 21px;
      padding-bottom: 10px;
      min-height: 34px;
    }

    > ul {
      padding-left:15px;
      top: 15px;
      position: relative;
      text-align: center;

      > li {
        list-style-type: none;
        padding: 10px;
        position: relative;
        text-align: left;

        &:before {
          content: '\232A';
          font-size: 12px;
          position: absolute;
          top: 12px;
          left: -10px;
        }

        a{
          text-align: left;
          font-size: 14px;
          color: $primaryColor9;
        }
      }
    }
  }
}