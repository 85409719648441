section.about_header {
  max-height: 750px;
  height: auto;

  img {
    max-height: 750px;
    height: auto;
  }
  .container {
    background-repeat: no-repeat;
    background-size: contain;
  }
}
section#about_us {
  h1.headline {
    margin-bottom: 6rem;
    .header_sm {
        @include fontSize(12px);
    }
  }

  .container {
    background-color: $primaryColor9;
  }
  .about_us_col {
    overflow-x: hidden;
    .wrapper {
        overflow-x: hidden;

      .text_container {
        padding-bottom: 20px;
        h2 {
          margin: 0 20% 20px;
        }
        p {
          margin: 0 20% 20px;
          color: $textLight2;
        }
      }
      img {
        overflow-x: hidden;
        height: 600px;
        position: relative;
        object-fit: cover;
        bottom: 0;

        @media #{$medium-down} {
          height: 350px;
        }
        @media #{$small-down} {
          height: 200px;
        }
      }
    }

    @media #{$large-down} {
      padding-bottom: 30px;
    }
  }
}
section.about_products {
  max-height: 800px;
  min-height: 500px;
  .container {
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    background-position-y: center;
    padding: 0;
    overflow: hidden;

    & > img {
      width: 100%;
      height:auto;

    }

    &:after {
      content: '';
      position: absolute;
      top: 1px;
      height: 800px;
      width: 100%;
      background: -moz-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(196, 196, 196, 0.27) 77%, rgba(255, 255, 255, 0) 100%);
      background: -webkit-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(196, 196, 196, 0.27) 77%, rgba(255, 255, 255, 0) 100%);
      background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(196, 196, 196, 0.27) 77%, rgba(255, 255, 255, 0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00ffffff', GradientType=1);

      @media #{$medium-down} {
        background: -moz-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(196, 196, 196, 0.27) 150%, rgba(255, 255, 255, 0) 100%);
        background: -webkit-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(196, 196, 196, 0.27) 150%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(196, 196, 196, 0.27) 150%, rgba(255, 255, 255, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00ffffff', GradientType=1);
      }
    }

    .world_class {
      height: 80%;
      border: white 3px solid;
      width: 36%;
      min-width:420px;
      position: absolute;
      top: 010%;
      left: 10%;
      padding: 30px;
      color: white;
      z-index: 100;
      @media #{$medium-down} {
        width:75%;
        min-width: initial;
      }
      .img_container{
        position: relative;
        width: 100%;
        text-align: right;
        height: auto;
        padding: 20px 30px 0;
        img {
          height: 10rem;
          right:0;
          position: relative;
          background-size: contain;
          z-index: 100;
        }
      }
      h2 {
        @include fontSize(50px);
        @include din2014extrabold;
        @media #{$xsmall-only} {
            @include fontSize(40px);
        }
      }
      p{
        @include din2014;
      }
    }

  }
  @media only screen and (max-width: 1700px) {
    .container {
    }
  }

  @media #{$medium-down}{
    .container {
      overflow: hidden;
      height: auto;
      max-height: 600px;

      img {
        width: auto;
        height:600px;
      }
    }

    .world_class {
      height: auto;
      width: 55%;
    }
  }
}
.split-interactive-header {
  .container {
    padding: 0 !important;

    & > div {
      position: relative
    }
  }

  .split-text {
    opacity: 0;
    position: absolute;
    top: 45%;
    width: 100%;
    color: white;
    transition: all 1s ease;

    h1, p {
      text-align: center;
    }
  }
  .split-left {
    float: left;
  }

  .split-right {
    float: right
  }
  .split-header-62 {
    width: 62.725%;
  }
  .split-header-38 {
    width: 37.275%;
  }

  img {
    transition: all 1s ease;
    width: 100%;
    height: auto;
  }

  .focus {
    .split-text {
      opacity: 1;
    }

    img {
      filter: brightness(.5);
      -webkit-filter: brightness(.5);
    }
  }
}

#about_slider{
  &.banner{
    overflow-y: hidden;

    .banner_slide{
      height:auto;
    }
  }
}