/*!
 * jQuery Cookiebar Plugin
 * https://github.com/carlwoodhouse/jquery.cookieBar
 *
 * Copyright 2012, Carl Woodhouse
 * Disclaimer: if you still get fined for not complying with the eu cookielaw, it's not our fault.
 */
.cookie-message {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 85px 0 20px;
  background: #fff;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 0 3px #ccc;
  z-index: 99; }

.cookie-message a {
  position: absolute;
  top: 0;
  *top: -2px;
  right: 20px;
  cursor: pointer; }

.cookie-message p, .cookie-message a {
  color: #333;
  font: 700 11px/18px Arial;
  margin: .6em 0; }

.blue {
  border-bottom: 1px solid #0E83AE;
  background-color: #0990c3;
  background: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0%, #0e83ae), color-stop(50%, #0990c3), color-stop(100%, #0e9dd2));
  background: -webkit-linear-gradient(#0e83ae, #0990c3, #0e9dd2);
  background: -moz-linear-gradient(#0e83ae, #0990c3, #0e9dd2);
  background: -o-linear-gradient(#0e83ae, #0990c3, #0e9dd2);
  background: -ms-linear-gradient(#0e83ae, #0990c3, #0e9dd2);
  background: linear-gradient(#0e83ae, #0990c3, #0e9dd2); }

.blue p, .blue a {
  color: #fff; }
