//<editor-fold desc="Slick-Carousel Styles">
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 55px;
  width: 28px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  &:hover, &:focus {
    outline: none;
    background: transparent;
    color: transparent;
    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
  &:before {
    font-size: 20px;
    line-height: 1;
    background-size: cover;
    height: 55px;
    width: 28px;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
.slick-prev {
  z-index: 100;
  left: 20px;
  [dir="rtl"] & {
    left: auto;
    right: 20px;
  }
  &:before {
    content: '';
    background: url(#{$imgpath}slider-arrow-left-drk.svg) no-repeat;
    background-color: rgba(0, 0, 0, 0.8);
    background-size: 55%;
    background-position: center;
    display: block;
    [dir="rtl"] & {
      content: '';
    }
  }
}
.slick-next {
  right: 20px;
  [dir="rtl"] & {
    left: 20px;
    right: auto;
  }
  &:before {
    content: '';
    background: url(#{$imgpath}slider-arrow-right-drk.svg) no-repeat;
    background-color: rgba(0, 0, 0, 0.8);
    background-size: 55%;
    background-position: center;
    display: block;
    [dir="rtl"] & {
      content: '';
    }
  }
}
.slick-slide {
    position: relative;

    .banner_slide{
        display: block !important;
    }
}
.slick-dots {
  list-style: none;
  max-width: 275px;
  margin: auto;
  position: relative;
  //bottom: 50px;
  padding-left:0;
  text-align: center;

  li {
    display: inline-block;
    position: relative;
    height: 10px;
    width: 10px;
    margin: 0 20px;
    padding: 0;
    cursor: pointer;

    &:first-child {
      margin-left:0;
    }

    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      transition: all 0.5s;
      &:hover, &:focus {
        outline: none;
        &:before {
          transition: all 0.5s;
          opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        transition: all 0.5s;
        position: absolute;
        top: 0;
        left: 0;
        content: '\2022';
        width: 30px;
        height: 30px;
        font-size: $slick-dot-size;
        line-height: 25px;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-on-hover;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    @media #{$small-down} {
        margin: 0 14px;
    }
    &.slick-active button:before {
      //color: $slick-dot-color-active;
      @include gradient1();
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      opacity: $slick-opacity-on-hover;
    }
  }
}
/*.folded-corner {
  width: 0;
  height: 0;
  position: absolute;
  top: -20px;
  right: -20px;
  border-width: 0;
  border-style: solid;
  border-color: #9ca #9ca transparent transparent;
  @include transition(border-width 0.2s ease-out, right 0.2s ease-in-out, top 0.2s ease-in-out);

  &:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: -20px;
    right: -60px;
    border-width: 0;
    border-style: solid;
    border-color: #009c96 #009c96 transparent transparent;
    @include transition(border-width 0.2s ease-out, right 0.2s ease-in-out, top 0.2s ease-in-out);
  }
}*/
.carousel_element.active .folded-corner {
  border-width: 0 40px 40px 0;
  right: 0;
  top: -1px;

  &:before {
    border-width: 0 40px 20px 0;
    top: -1px;
    right: -40px;
    z-index: 100;
  }
}
//.carousel_element .folded-corner {background: hsla(260,5%,75%,0.5);}


@media #{$small-down} {

  .slick-arrow {
    display:none;
  }
}

