header {
  width: 100%;
  background-color: white;
  //opacity: 0.8;

  z-index: 1000;
  position: fixed;
  margin:auto;
  left:0;
  right:0;
  height: $headerHeight;

  @media screen and #{$small-down} {
    position: relative;
  }

  nav {
    max-width: $screen-xlg;
    margin: auto;
    //@include shiftCenter();
    top: 30px;
    position: relative;

    a {
      color: $primaryColor8;
      &:hover {
        text-decoration: none;
      }
    }


      &.container {
        padding:0 70px;
        @media #{$medium-down} {
          width: 750px;
        }
      }

    ul.main {
      display: inline-block;
      float: right;

      > li {
        display: inline-block;
        border-radius: 15px;
        cursor: pointer;

        @media #{$medium-up}{
          padding: 3px 15px;
          margin: 0;
        }
        @media #{$large-up}{
          padding: 3px 25px;
          margin: 0 5px;
        }

        &.has_submenu {
          position: relative;
          a {
            position:relative;
          }
           .submenu_ctrl {
             height: 20px;
             width: 20px;
             position: absolute;
             font-size: 20px;
             top: 26px;
             margin: auto;
             text-align: center;
             left: 50%;
             transform: translateX(-50%);
             color: $gradientColor1b;


          }
        }

        a {
          color: $primaryColor8;
          font-size: 14px;
          padding: 0;
          text-transform: uppercase;
          letter-spacing: 0.03em
        }

        &.active {
          @include gradient1();
          a {
            color:$textWhite;
          }
        }
      }
    }
    .hidden_submenu {
      display: none;
    }


    .contact_social, .mobile_topbar{
      display: none;
    }
  }

  #logo {
    position: relative;
    //background:  url(#{$imgpath}euradrives-logo.svg);
    background-image: createSvgUrl(logo_sm, #000);
    width: 90px;
    height: 30px;
    background-size: 90px 30px;
    float: left;
    margin-left: 30px;
  }
  .header_logo{
    width: 90px;
    height: 30px;
    float: left;

    img {
      width: 90px;
      height: 30px;
    }
  }

  & > .header_logo {
    display:none;
  }

  @media #{$medium-up} { width: 960px; }
  @media #{$large-up} { width: 1160px; }
  @media #{$x-large-up} {width: 1440px;}
  @media #{$xx-large-up} {width: 1440px;}

  @media #{$medium-down} { display: none; }
}


#lang_menu, .mobile_lang_menu {
  padding: 3px 0 0 45px;
  display: inline-block;
  float: right;
  position: relative;

  ul {
    background-color: white;
    position: absolute;
    padding:10px;
    width:100px;
    transition: all ease 0.5s;
    @media #{$medium-up} {
      top:60px;
    }

    li {
      list-style: none;
      padding: 5px 0;
      a {
        color:black;
        &:hover{
          text-decoration: none;
        }
      }
      img.flag {
        height:10px;
        width:10px;
        display: inline-block;
      }

      &.current_lang {
        span {
          color: gray;
        }
      }
    }
  }
  span.current_lang {
    cursor: pointer;
    @include din2014bold;
    font-size: 14px;
    color:white;
  }
}
.mobile_lang_menu {
  ul {
    right:0;
  }
  span.current_lang {
    color:black;
  }
}

body {
  &.frontpage {
    header {
      background-color: $primaryDarkColor1;
      opacity: 0.8;

      #lang_menu {
        color:white;
        ul {
          background-color: $primaryDarkColor1;
          //opacity: 0.8;
          top: 48px;
          a {
            color: $primaryColor8;
          }
        }
      }
      #logo {
        background-image: createSvgUrl(logo_sm, #fff);
      }
    }
  }
  &:not(.frontpage) {
    header {
      @include box-shadow(rgba(0, 0, 0, 0.31) 2px 3px 20px 3px);

      ul.main {
        li:not(.active) {
          a{
            color:black;
          }
        }
      }
    }
    #lang_menu span.current_lang, .mobile_lang_menu span.current_lang {
      color: black;
    }
  }

  #lang_menu span.current_lang, .mobile_lang_menu span.current_lang {
    position: relative;
    &:before {
      content:"";
      position: absolute;
      top: -2px;
      bottom: -3px;
      left: -7px;
      right: -3px;
      background: url(#{$imgpath}/lang-frame.svg) no-repeat ;
      background-size: cover ;
      width: 45px;
      height: 25px;
    }
  }

  //mobile menu

  .slicknav_menu {
    display: none;
    background-color: white;
    position: fixed;
    z-index: 100;
    width:100%;


    @media #{$medium-down} {
      display: block;
      position: relative;
    }
    .slicknav_btn {
      float:left;
      background-color: white;

      .slicknav_icon-bar{

      }
      .slicknav_icon-bar:last-child{
        //display:none;
      }
    }
    .submenu_ctrl {
      display: none;
    }

    .mobile_topbar {
      display: block;
      width: 100%;
      padding: 5px 15px;

      .mobile_top_logo {
        float:left;
        max-width:50%;
        position: relative;
        left: 45%;
        transform: translateX(-70%);

        img{
          height: 30px;
        }
      }

      .mobile_lang_menu {
        float:right;
        z-index: 1;
      }
    }
  }
  .slicknav_nav {
    position: relative;
    z-index: 100;
    color:black;
    a {
      color:black;
      padding:3% 5px;

      &:hover {
        background-color: white;
        color: #000;
        text-decoration: none;
        border-radius: 0;
      }
    }

    > li:first-child > a {
      @include din2014bold;
      position: relative;
    }
    > li:first-child > a:after {
      content:'';
      width: 60px;
      height:3px;
      @include gradient1;
      position: absolute;
      bottom:-2px;
      left:0;
    }

    a:not(.menu_btn), .slicknav_parent-link{
      border-bottom:$primaryColor1 1px solid;


    }

    .slicknav_parent-link {
      .slicknav_item {
        border-bottom: 0;
      }
    }


    .slicknav_item,.slicknav_row  {
      padding:5px 5px;
      &a, a {
        padding:20px 5px;
      }
    }

    .slicknav_row:hover {
      background-color: white;
      color: #000;
      text-decoration: none;
      border-radius: 0;
    }

    .slicknav_arrow {
      float:right;
      margin-right:30px;
    }

    li{
      position: relative;
      .contact_social {
        padding-top:20px;
        position: relative;
        transform:translateX(-50%);
        left:50%;
        a {
          display: inline-block;
          border-bottom: 0 !important;
          padding:0;

          div.social_icon{
            padding: 0;
          }
        }
        @media (max-width:380px){
          left:70%;
        }
      }
    }


  }
}
.real_link {
  display: none !important;
}
