html {

    font-size: $base-fontsize;

    &.wf-loading {
        visibility: hidden;
    }

  body {
    @include din2014light;
    @include fontSize($base-fontsize);    

    position: relative;

    section {
      &.content {


        h1 {
            margin:auto;
            text-align: left;
            @include din2014extrabold();
            @include fontSize(50px);

          &.text-center {
            text-align: center;
          }
        }

        h2, h3, h4, h5, h6 {
            margin:auto;
            text-align: left;
            @include din2014extrabold();
            @include fontSize(36px);

          &.text-center {
            text-align: center;
          }

          &.border-bottom {
            border-bottom: 1px solid $primaryDarkColor5;
          }
        }

        span.header_sm {
            @include fontSize(15px);
            color:$textLight2;
        }

        > .container {
            padding: 40px 150px;
            background-color: #f2f2f2;

          &.background-white {
            background-color: #fff;
          }

          &.background-medium-dark {
            background-color: $primaryColor10;
          }

          &.has-overhang {
            overflow: hidden;
            &>.shadow-bottom-overhang {
              position: relative;
              top: -50px;
              width: calc(100% + 300px);
              margin-left: -150px;
              box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.25);
            }
          }
        }

        @media #{$small-down}{
            h1, h2, h3, h4, h5, h6 {
                @include fontSize(30px);
            }
            .container {
                padding: 40px 40px 60px;
            }

        }
      }

    }
    .match_h {
      position: relative;
    }

    .header_spacer{
      height:$headerHeight;
      @media #{$small-down} {
        display:none;
      }
    }
  }

  .slick-initialized {
    visibility: visible!important;
  }

  h1.headline {
    @include fontSize(50px);
    margin-bottom: 40px;
    @include din2014extrabold;
    @include headerStyleSmall();
    .header_sm {
      @include din2014light();
      top: 20px;
      &:before {
        top: 7px;
      }
    }
  }

  a:focus{
    outline:none;
  }
}

@include headerStyleSmall;

.banner_section {
  padding: 0;
  height: auto;
  max-height: 410px;
  .container {
    padding:0;
  }
  img {
    padding: 0;
    height: auto;
    max-height: 410px;
    width:100%;
  }
}

.hide-small {

}
@media #{$large-up} {
  .row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
  }
}

.as-subheadline {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #818181;
}

hr.separator {
  border-color: $primaryDarkColor2;
}

picture {
  img {
    max-width: 100%;
    height: auto;
  }
}
@media screen and #{$large-up} {
  .row {
    [class^="col-"] {
      &.bottom-aligned-pair {
        float: none;
        display: table-cell;
        vertical-align: bottom;
      }
      &.top-aligned-col-partner {
        vertical-align: top;
      }
    }
  }
}

.section-caption {
  margin:  1rem 0;
  h2,h3,h4 {
    font-size: 1.5rem;
    color: $eura-green-light;
    margin: 10px 10px;
  }
}

.color-green-bright {
  color: $eura-green-light;
}