//  position: relative;
//top: $headerHeight;

#products_header {
  height: 180px;
  background-color: white;

  .container {
    padding: 70px 25%;
  }

  .product_nav {
    position: relative;
    a {
      color: $text-dark1;
      img {
        //@include shiftCenter();
        width: 100%;
        height: 50px;
        //background-color: $textLight1;
      }

      span {
        text-align: center;
        @include shiftCenter();
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}
#products_solutions {
  .container {
    background-color: $secondaryColor1;
    position: relative;
    padding-left: 6.5%;

    .solution_banner_content {
      padding-top: 60px;
      z-index: 100;

      h1 {
        color: white;
        font-size: 60px;
        @include din2014bold;
      }
      p {
        color: white;
        font-size: 20px;
        margin-bottom: 10px;
        @include din2014light;
      }

      .button_container {
        position: relative;
        width: 100%;
      }
      .button {
        @include din2014bold;
        position: relative;
        float: right;
      }
    }

    .solution_img {
      position: absolute;
      width: 100%;
      height: auto;
      max-height: 440px;
      overflow: hidden;
      top: 0;
      left: 0;
      img {
      }
    }

    #banner_mask {
      z-index: 50;
      height: 100%;
      position: absolute;
      width: 41.6%;
      top: 0;
      left: 0;
      background-color: black;
      opacity: 0.6;
    }

    @media #{$small-down} {
      background-color: white;
      .solution_img {
        position: relative;
        min-height: 100px;
        width: 100%;
        height: auto;
        img {
          width: 100%;
          height: auto;
        }
      }
      .solution_banner_content {
        padding-top: 10px;
        h1 {
          font-size: 40px;
        }
        h1, p {
          color: black;
          text-align: center;
        }
        .button_container {
          text-align: center;
          .button.primaryButton {
            float: none;
            display: block;
            position: relative;
            margin: auto;
          }
        }
      }
      #banner_mask {
        display: none;
      }
    }

  }

}
#products_inverter {
  position: relative;
  .container {
    //background-color: $primaryColor9;
    .carousel {
      margin:auto;
      max-width: 1100px;
    }

    #inverter_carousel {
      width: 92%;
      margin: auto;
      @media #{$small-down} {
        width: 100%;
      }

      .inverter_ele {
        &.active {
        }
        .inv_pic {
        }
        .inv_info {
          h2 {
            &:after {
            }
          }
        }
      }
    }
  }
}
#products_motor {
  position: relative;

  .container {
    background-color: white;

    .side_indicator {
      .motor_ind_ele {
        cursor: pointer;
        transition: all ease 0.5s;
        padding: 5px;
        margin-left:50px;

        h3, .motor_text {

          color: $textLight1;
          transition: all ease 0.5s;
        }
        h3 {
          font-size: 50px;
          padding: 0 15px;
          background-color: $textLight1;
          color:white;
          text-transform: uppercase;
        }
        .motor_text {
            font-size: 13px;
        }
        a{
            font-size: 13px;
        }

        &.active {
          //background-color: $tableColor-odd-light;

            h3 {
                @include gradient1();
                color:white;
            }

            .motor_text {
                color: black;
                font-size: 13px;
            }
        }

        @media #{$small-down} {

          .motor_ind_ele {
            display: inline-block;
            padding-right: 10px;

            .motor_text {
              display: none;
            }

          }
        }
      }
    }

    .carousel {
      max-height: 400px;
      //width: 50%;
      margin: 40px auto;

      .motor_img {
        //width: 80%;
        margin: auto;
        img {
          //width: 100%;
          margin: auto;
          @media #{$large-up}{
            width: auto;
            min-height:380px;
            max-height: 400px;
          }
        }
      }
      .slick-next:before, .slick-prev:before {
        background-color: transparent;
      }

      @media #{$medium-up}{
        .slick-next {
          right: -60px;
        }
        .slick-prev {
          left:0;
        }
      }
      @media #{$medium-down} {
        width: 100%;

        .slick-next {
          right: -10px;
        }
        .slick-prev {
          left: -10px;
        }
      }

    }
  }
}
#products_controller {
  position: relative;
  .container {
    background-color: $primaryColor9;

    .carousel {
      margin:auto;
      max-width: 1100px;
    }

    #controller_carousel {
      width: 80%;
      margin: auto;

      @media #{$small-down} {
        width: 100%;
      }

      .controller_ele {
        .cntr_pic {
        }
        .cntr_info {
          h2 {
            &:after {
            }
          }
        }
      }
    }
  }
}
.carousel_section {

  .indicator {
    text-align: right;
    top: -20px;
    position: relative;
    ul {
      list-style: none;
      li {
        @include din2014;
        display: inline-block;
        font-size:18px;
        color: $textLight2;
        padding: 0 20px;
        cursor: pointer;
        transition: all 0.5s;
        //height: 40px;
        vertical-align: middle;
        &.active {
          color: black;
          //@include din2014bold;
        }

        &:last-child {
          //margin-bottom: 20px;
        }
      }
    }
  }

  .carousel_element {
    width: 85%;
    //height: 100%;
    //max-height: 585px;
    margin: auto;
    display: block;
    position: relative;

    @media #{$small-down} {
      width: 60%;
      margin-bottom:20px;
    }

    &:after {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity 0.75s ease-in-out;
      box-shadow: 5px 3px 11px 1px rgba(0, 0, 0, 0.2), 10px 9px 19px 1px rgba(0, 0, 0, 0.19);
      opacity: 0;
      z-index: -1;
    }
    &.active:after {
      opacity: 1;
    }
    &>a:hover ,
    &>a:visited ,
    &>a:active {
      text-decoration: none;
    }

    &>a:not(:hover) {
      // override colors

      h1,h2,h3,p{
        color: #333;
      }
      .link-text {
        color: #9e9e9e;
      }
    }

    .carousel_info {
      @include gradient1();
      position:relative;
      height: 40%;
      padding: 10%;
      z-index: 100;
      @include fontSize(14px);
      transition:background 0.5s ease-in-out;
      @media #{$x-large-up} { min-height:290px; position: relative;}
      .cover {
        z-index: -1;
        position:absolute;
        background-color: white;
        left:0;
        right:0;
        top:0;
        bottom:0;
        opacity: 1;
        transition: opacity 0.5s ease-in-out;

      }

      p {
          transition:color 0.5s ease-in-out;
        }

      h2 {
        margin-bottom: 50px;
        position: relative;
        @include fontSize(20px);
        transition:color 0.5s ease-in-out;

        &:after {
          content: '';
          width: 30px;
          display: block;
          position: absolute;
          bottom: -30px;
          border-bottom: $primaryDarkColor2 2px solid;
          transition: border-color 0.5s ease-in-out;
        }

        span{
            display: block;
        }
      }
      .link-text {
        @media #{$x-large-up} { position: absolute; bottom:40px; }
      }
    }

    &.active {
      .carousel_info {
        border-color:white;

        color:white;
        h2:after {color:white; border-color:white;}
        a,p {color:white}
         .cover {
          opacity:0;
        }
      }
    }
    .carousel_pic {
      background-color: $secondaryColor2;
      height: 60%;
      @media #{$x-large-up} { min-height:290px; max-height: 290px;}
    }

  }
}
.carousel_container.eura_slider {

  .slick-slider {
    .slick-slide {
      max-height: 585px;
      //margin-bottom: 30px;
    }
    .slick-prev {
      &:before {
        background-color: transparent;
      }

      left: -20px;
    }
    .slick-next {
      &:before {
        background-color: transparent;
      }
      right: -20px;
    }

    .slick-dots {
      bottom: -20px;
      text-align: center;
      padding: 0;
    }
  }

    &.background-dark {
      background-color: $primaryDarkColor6 !important;

      h1, .header_sm {
        color: white;
      }

      .header_sm::before {
        border-color: white;
      }

     .indicator ul li{
       color: white;
     }
    }
}

.single-link-section {

  .teaser.copy {
    margin-bottom: $space-default;
  }

  .display-image-wrapper {
    @media screen and #{$medium-up} {
      min-height: 260px;
    }
    position: relative;

    .display-image {
      &.bottom-align {
        img{
          position: relative;
          bottom: -#{$space-medium};
          max-height: calc(100% - 40px);
        }
      }
    }
  }
}

.product-group {

  max-width: 950px;
  margin-left: auto;
  margin-right: auto;

  .picture-wrapper {
    background-color: $primaryColor9;

    img {
      width: 100%;
    }
  }

  .label-row {
    & > div {
      display: inline-block;
      padding: 0.5rem;
      &:first-child {
        background-color: $primaryDarkColor5;
        color: white;
        font-weight: bold;
        font-size: 1.5rem;
      }
      &:last-child {
        float: right;
        font-size: 1rem;
        line-height: 34px;  // centered vertically
      }
    }

  }
}