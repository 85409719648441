$primaryDarkColor1 : #1a1a1a;
$primaryDarkColor2 : #333333;
$primaryDarkColor3 : #58595b;
$primaryDarkColor4 : #818282;
$primaryDarkColor5:  #B8BCBF;
$primaryDarkColor6:  #293133;

$gradientColor1    : #158085;
$gradientColor1b   : #158389;

$eura-green: #277073;
$eura-green-light: #00bcc8;

$gradientColor2    : #99ccaa;

$primaryColor1     : #999999;
$primaryColor6     : #b4b5b7;
$primaryColor5     : #b3b3b3;
$primaryColor7     : #c7c7c7;
$primaryColor4     : #d1d3d4;
$primaryColor8     : #d0d2d3;
$primaryColor9     : #efefef;
$primaryColor10     : #ededed;

$secondaryColor1: #aaaaaa;
$secondaryColor2: #c9c9c9;


$light1: #f3f3f3;
$light2: #f2f2f2;

$shadow1: #bebebe;

$tableColor-odd-light: #e6e6e6;
//$tableColor-odd: #cccccc;
//$tableColor-even: #efefef;
$tableColor-odd: #f5f6f7;
$tableColor-even: #f2f2f2;

$text-dark1: #4d4d4d;
$textLight1: #969696;
$textLight2: #808080;
$textWhite : white;

$link-color: $eura-green;

@mixin gradient1() {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#158085+0,158085+20,99ccaa+100 */
  background: rgb(21,128,133); /* Old browsers */
  background: -moz-linear-gradient(left, rgba(21,128,133,1) 0%, rgba(21,128,133,1) 20%, rgba(153,204,170,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(21,128,133,1) 0%,rgba(21,128,133,1) 20%,rgba(153,204,170,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(21,128,133,1) 0%,rgba(21,128,133,1) 20%,rgba(153,204,170,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#158085', endColorstr='#99ccaa',GradientType=1 ); /* IE6-9 */
}

@mixin gradient2() {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#038084+1,028084+21,6b8e70+100 */
  background: rgb(3,128,132); /* Old browsers */
  background: -moz-linear-gradient(left, rgba(3,128,132,1) 1%, rgba(2,128,132,1) 21%, rgba(107,142,112,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(3,128,132,1) 1%,rgba(2,128,132,1) 21%,rgba(107,142,112,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(3,128,132,1) 1%,rgba(2,128,132,1) 21%,rgba(107,142,112,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#038084', endColorstr='#6b8e70',GradientType=1 ); /* IE6-9 */
}

@mixin gradient3() {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#038084+1,028084+21,6b8e70+100 */
  background: rgb(3,128,132); /* Old browsers */
  background: -moz-linear-gradient(left, rgba(3,128,132,1) 1%, rgba(2,128,132,1) 6%, rgba(107,142,112,1) 33%, rgba(201,201,201,1) 66%, rgba(201,201,201,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(3,128,132,1) 1%,rgba(2,128,132,1) 6%,rgba(107,142,112,1) 33%, rgba(201,201,201,1) 66%, rgba(201,201,201,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(3,128,132,1) 1%,rgba(2,128,132,1) 6%,rgba(107,142,112,1) 33%, rgba(201,201,201,1) 66%, rgba(201,201,201,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#038084', endColorstr='#6b8e70',GradientType=1 ); /* IE6-9 */
}

@mixin gradient4() {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#038084+1,028084+21,6b8e70+100 */
  background: rgb(3,128,132); /* Old browsers */
  background: -moz-linear-gradient(left, rgba(3,128,132,1) 1%, rgba(2,128,132,1) 6%, rgba(107,142,112,1) 33%, rgba(201,201,201,0) 66%, rgba(201,201,201,0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(3,128,132,1) 1%,rgba(2,128,132,1) 6%,rgba(107,142,112,1) 33%, rgba(201,201,201,0) 66%, rgba(201,201,201,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(3,128,132,1) 1%,rgba(2,128,132,1) 6%,rgba(107,142,112,1) 33%, rgba(201,201,201,0) 66%, rgba(201,201,201,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#038084', endColorstr='#6b8e70',GradientType=1 ); /* IE6-9 */
}


@mixin gradient5() {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#038084+1,028084+21,6b8e70+100 */
  background: rgb(3,128,132); /* Old browsers */
  background: -moz-linear-gradient(left, rgba(3,128,132,1) 1%, rgba(2,128,132,1) 6%, rgba(107,142,112,1) 33%,  rgba(21,128,133,1) 61%,rgba(21,128,133,1) 66%,rgba(153,204,170,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(3,128,132,1) 1%,rgba(2,128,132,1) 6%,rgba(107,142,112,1) 33%,  rgba(21,128,133,1) 61%,rgba(21,128,133,1) 66%,rgba(153,204,170,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(3,128,132,1) 1%,rgba(2,128,132,1) 6%,rgba(107,142,112,1) 33%,  rgba(21,128,133,1) 61%,rgba(21,128,133,1) 66%,rgba(153,204,170,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#038084', endColorstr='#6b8e70',GradientType=1 ); /* IE6-9 */
}

// green to transparent - over part

@mixin gradient6() {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#038084+1,028084+21,6b8e70+100 */
  background: rgb(3,128,132); /* Old browsers */
  background: -moz-linear-gradient(left, rgba(3,128,132,1) 1%, rgba(2,128,132,1) 6%, rgba(107,142,112,1) 33%,  rgba(21,128,133,1) 61%,rgba(21,128,133,1) 66%,rgba(153,204,170,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(3,128,132,1) 1%,rgba(2,128,132,1) 6%,rgba(107,142,112,1) 33%,  rgba(21,128,133,1) 61%,rgba(21,128,133,1) 66%,rgba(153,204,170,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(3,128,132,1) 1%,rgba(2,128,132,1) 6%,rgba(107,142,112,1) 33%,  rgba(21,128,133,1) 61%,rgba(21,128,133,1) 66%,rgba(153,204,170,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#038084', endColorstr='#6b8e70',GradientType=1 ); /* IE6-9 */
}




@mixin gradient1-transparent() {
  /* http://www.colorzilla.com/gradient-editor/#158085+0,158085+20,99ccaa+100&0.45+0,0.51+100 */
  background: -moz-linear-gradient(left, rgba(19,116,123,1) 0%, rgba(21,128,133,0.85) 20%, rgba(153,204,170,0.75) 100%);
  background: -webkit-linear-gradient(left, rgba(19,116,123,1) 0%,rgba(21,128,133,0.85) 20%,rgba(153,204,170,0.75) 100%);
  background: linear-gradient(to right, rgba(19,116,123,1) 0%,rgba(21,128,133,0.85) 20%,rgba(153,204,170,0.75) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c213747b', endColorstr='#bf99ccaa',GradientType=1 );
}