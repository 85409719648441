.flexButton{

  flex: 1 1 auto;
  overflow:hidden;
  z-index: 0;
  float: left;
  margin-right: 1.25rem;

  &:last-child{
    margin-right: 0;
  }

  &:after {
    position: absolute;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    will-change: left, width;
    content: "";
    width: 0;
    bottom: 0;
    @include gradient2();
    height: 120%;
    left: -10%;
    transform: skewX(15deg);
    z-index: -1;
  }

  &:hover{

    &:after {
      left: -10%;
      width: 120%;
    }
  }

  &:disabled {
    pointer-events: none;
    &:after{
      display: none;
    }
  }
}

.button{
  display: inline-block;
  position: relative;
  @include fontSize(14px);
  font-weight: 500;
  letter-spacing: 0.0625rem;
  text-align: center;
  color: $light1;
  padding: 1rem 1.25rem 0.925rem;
  text-decoration: none;
  cursor: pointer;
  //text-transform: uppercase;
  border: 0;

  &:hover, &:visited{
    text-decoration: none;
    color: $light1;
  }

  /*****  icon styles for buttons  ******\

  &.icon{

    padding-left: 3.25rem;

    &:before{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 2.8125rem;
      background-repeat: no-repeat;
      background-position: 1.3125rem center;
      background-size: 1.5rem 1.5rem;
    }
    &.icon-mail{
      &:before{
        background-image: simpleIcon(phone, $light);
      }
    }
    &.icon-phone{
      &:before{
        background-image: simpleIcon(phone, $light);
      }
    }
    &.icon-download{
      &:before{
        background-image: simpleIcon(download, $light);
      }
    }

  }
  ****************/
}

.primaryButton {
  //@extend .flexButton;
  @extend .button;
  @include din2014light;
  color: white;
  border: none;
  height:50px;
  width:180px;
  letter-spacing: 0.1rem;
  overflow: hidden;
  transition: all  0.5s;
  z-index: 10;
  background-color: transparent;
  //@include gradient1();
  &:after {
    content:"";
    position: absolute;
    width: 100%;
    background-size: 1% 100%;
    height:120%;
    left:0;
    top:0;
    z-index: -3;
    @include gradient1();
    transition: all  0.5s;
  }
  &:before {
    content:"";
    position: absolute;
    width: 100%;
    background-size: 1% 100%;
    height:120%;
    left:0;
    top:0;
    z-index: -1;
    background-color: $primaryColor1;
    opacity: 1;
    transition: all  0.5s;
  }
  &:hover {
    &:after {
      //left:0;
    }
    &:before {
      opacity: 0;
    }
  }
  &.reverse {
    &:before {
      opacity:0;
    }
    &:hover {
      &:before {
        opacity:1;
      }
    }
  }
  &:active {
    &:after {
      left:0;
    }
  }
}


.button.solid_outline {
  background: none;
  border: 1px solid transparent;
  border-radius: 0;
  @include borderGradient();
  padding-top:10px;
  height: 39px;
  width: 141px;

  &:after {
    content:"";
    @include gradient4();
  }
  &:hover {
    color:$primaryDarkColor2;
  }
}



 .btn:focus, .btn:active, .btn:active:focus {
   outline:none;
   background: initial;
 }

  .play_video {
     height: 55px;
     width:80px;
     background: url(#{$imgpath}play_video.svg);
     background-repeat: no-repeat;
     background-size: contain;
     border: none;

     &:focus {
       background: url(#{$imgpath}play_video.svg);
       background-repeat: no-repeat;
       background-size: contain;
       border: none;
       box-shadow: none;
     }
     &:active {
       background: url(#{$imgpath}play_video.svg);
       background-repeat: no-repeat;
       background-size: contain;
       border: none;
       box-shadow: none;
       &:focus {
         background: url(#{$imgpath}play_video.svg);
         background-repeat: no-repeat;
         background-size: contain;
         border: none;
         box-shadow: none;
       }
     }

   }