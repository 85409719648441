//#contact_wrapper {

.contact_header {
  height: 340px;
  .container {
    background-size: cover;
    height: 340px;
  }
}
#contact_info {
  .container {
    background-color: $primaryColor9;
  }
  h1, h2 {
    @include headerStyleSmall;
    text-align: left;
    margin-bottom: 50px;
    color: $primaryDarkColor1;
    @include borderGradient;
  }

  #map-inset {
    padding: 10px;
    background: white;

    #google-map {
      width: 100%;
      height:550px;
    }
  }

  #contact_details {
    font-size:18px;
  .contact_head_text {
    color:black;
  }
    .detail_block {
      margin-bottom: 3rem;
    }

    hr {
      border-color: $primaryDarkColor2;
    }
    .green_text {
      color: $gradientColor1;
    }
    .grey_text {
      color: $textLight2;
    }
    @media #{$small-down} {
      margin-top:$margin-small-down;
    }
  }
}

@media #{$small-up} {
  .row.contact_bottom {
    position: absolute;
    width: 100%;
    bottom:0;
  }

}

.contact_social {

  div {
    display: inline-block;
  }

  a {
    display: inline-block;
    svg {
      width: 40px !important;
      height: 40px !important;
      margin-right: 10px;
    }
    .fil0 {fill: $primaryDarkColor3}
    .fil1 {fill: #fff; transition: fill 0.5s;}

    &:hover {
      text-decoration: none;
      .fil0 {
        fill: $gradientColor1
      }
    }
  }
  @media #{$small-up} {
    display:none;
  }
}
.legal.button {
  height: 23px;
  width: 110px;
  padding:3px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  font-size: 13px;
  float: right;
  background-color: #c9c8c8;
  text-transform: uppercase;
  @include din2014;
}
//}