//#frontpage_wrapper {

section.banner {
  position: relative;

  .carousel_container {
    height: 690px;
    margin: auto;
    margin-bottom: -25px;
    padding-left: 0;
    padding-right: 0;

    .slick-dots {
      bottom: 70px;
    }

    img {
      width: auto;
    }

  }
  .banner_slide {
    position: relative;
    height: 690px;
  }
  .slide_content {
    position: absolute;
    top: 30%;
    left: 0;
    width: 100%;
    //font
    text-align: center;
    color: white;

    h1 {
      @include din2014extrabold;
      font-size: 80px;
    }
    p {
      font-size: 21px;
      margin: 5px auto 36px;
      text-align: center;
      max-width: 560px;
    }
    .button_container {
      position: relative;
      width: 330px;
      margin: 0 auto;
      a {
        height:40px;
        width: 145px;
        padding-top:12px;
        margin: 10px;
        &:not(.outline):after {
          //@include gradient6();
        }
      }

      @media #{$xsmall-only} {
        width: 300px;
        a {
          position: relative;
          width: 170px;
          margin: 0 25% 10px;
        }
      }

    }
    a.outline {
      background: none;
      border: 1px solid transparent;
      @include borderGradient();
      padding-top:10px;
      height: 39px;
      width: 141px;
      border-radius: 0;

      &:after {
        @include gradient4();
      }
      &:active {
        color:$primaryColor5;
      }
      &:focus {
        color:$primaryColor5;
      }
    }

    @media #{$medium-down}{
      top: 150px;
      width: 90%;
      margin-left: 5%;
    }
    @media #{$small-down}{
      top: 50px;
    }
  }

  .slick-dots {
    bottom: 100px;
  }
  .slick-prev {
    left: 0;
    top: 350px;
  }
  .slick-next {
    right: 0;
    top: 350px;
  }
}
body.frontpage {
  section.banner {
    .carousel_container {
      //height: 103vh;
      //width: 100vw;
      //max-width: 100%;
      .carousel {
        //height: 100vh;
        //width: 100vw;
        //max-width: 100%;

        .banner_slide {
          height: auto;
          img {
            width: auto;
            //height: 100vh;
          }
        }
      }
    }
  }
  section.content {
    position: relative;
    background-color: white;
    .container{
      padding:40px 70px;
    }
  }

  .frame-type-euradrives_fpnews .container {
    padding: 40px 0px 0 !important;

    .row {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
}
section.content {
  &#fp_products /*, &#fp_about, &#fp_download, &#fp_news */
  {

    > .container {
      background-color: $light2;
      padding-top: 40px;
      padding-bottom: 50px;
    }

    .product_element {
      a {
        color: inherit;

        &:hover {
          text-decoration: none;
        }
      }
    }
    h2 {
      margin-bottom:60px;
    }
    @media #{$small-down}{
      h2, h3 {
        text-align: center;
      }
      h2 {
        margin-bottom: 2rem;
      }
      //mobile subheader
      h3 {
        @include fontSize(12px);
      }

      .primaryButton {
        width: 27%;
        margin: 10px 36% 0;
        min-width: 125px;
      }

    }
  }

  h2 {
    &.section_header {
      margin-bottom: 60px;
      text-align: center;

      &:after {
        content: '';
        width: 55px;
        border-bottom: 2px solid $primaryDarkColor2;
        top: 30px;
        @include shiftCenter();

        @media #{$small-down}{
          display: none;
        }
      }
    }
  }

  .product_element {
    text-align: center;
    transition: all 0.5s;
    padding: 40px 0;

    &.row {
      margin: 0 10px;
    }

    h3 {
      text-align: center;
      padding-top: 25px;
      margin:30px 10px 0;
      font-size:20px;
      border-top: solid 1px $primaryColor7;
      text-transform: uppercase;

      @media #{$medium-down}{
        border-top: 0;
      }
    }
    p {
      @include din2014;
      color: $textLight2;
      line-height: 2.2rem;
      font-size: 13px;
      margin-top:20px;
    }

    .product_image {
      position: relative;
      min-height: 210px;
      max-width: 90%;

      img {
      }

      .lines, .solid {
        transition: all 0.5s;
        position: absolute;
        bottom: 0;

        width: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
      .solid {
      }

      .lines {
        opacity: 1;
        background-color: $light2;
      }
    }
    @media #{$large-down}{
      .product_image {
        max-width: 75%;
        //margin: auto;

      }
    }
    @media #{$medium-down}{
      .product_image {
        max-width: 30%;
        margin-top: 2rem;
        padding-bottom: 1rem;
        margin-left: 10px;
      }
    }

    &.active {
      background-color: white;

      .lines {
        opacity: 0;
      }
    }

  }
}
#fp_about {
  .row {
    margin-right: 0;
    @media #{$medium-down} {
      margin-left: 0;
    }
  }
  .container {
    padding: 0;
    margin: auto;
  }
  #about_img_container {

      
      @media #{$medium-up}{
        height: 500px;
        width: 45%;
        padding-right: 0;
    }
    @media #{$medium-down} {
        height: auto;
        padding:0;
    }
    @media #{$small-down} {
        display: none;
    }

    .about_img{
        height: 100%;
    }
  }

  #about_info {
    background-color: $primaryDarkColor2;
    color: white;
    height: 500px;
    position: relative;
    padding: 2rem 5rem 0 10px;

    @media #{$medium-up}{
      width: 55%;
    }
    @media #{$medium-down}{
      height: auto;
      padding-bottom: 20px;
    }

    @media #{$small-down}{
      display: none;
    }

    @media #{$xsmall-only}{
      padding: 2rem 5rem 0 2rem;
    }
    /** triangle shape */
    &:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 503px 140px;
      border-color: transparent transparent $primaryDarkColor2 transparent;
      position: relative;
      left: -149px;
      top: -34px;

      @media #{$medium-down} {
        display: none;
      }
    }

    h3, h2 {
      @include din2014extrabold;
      @include fontSize(36px);
      text-align: left;
      margin-bottom:10px;
      margin-top: 20px;
    }

    .txt_row {
      padding-top: 20px;
      @include clearfix;

      &:last-child {
        .lg_nmbr {
          padding-bottom: 25px;
        }
      }

    }
    .lg_nmbr {
      display: inline-block;
      float: left;
      padding-right: 2rem;
      line-height: 1.3;

      span {
        @include din2014extrabold();
        @include fontSize(80px);
        top: -10px;
        position: relative;

        @media only screen and (max-width: 520px) {
            @include fontSize(40px);
          top: 0;
        }

        sup {
          top: -0.9em;
          font-size: 44%;
        }
      }
      @media only screen and (max-width: 1200px) {
        span {
            @include fontSize(60px);
        }
      }
      @media only screen and (max-width: 520px) {
        width: 100%;
      }

    }
    .sm_container {
      display: block;
      height: 60px;
      position: relative;
      top: 0px;

      span {
        &.sm_prop {
          @include din2014bold;
          font-size: 30px;
          text-transform: uppercase;
          position: relative;
          top: 5px;
        }

      }
      span {
        &.sm_desc {
          @include din2014light;
          font-size: 19px;
          line-height: 1.5;
          position: relative;
          font-weight: 100;
          bottom: -5px;
        }

      }
    }

    .globe {
      position: absolute;
      top: 6rem;
      right: 6rem;
      background-size: contain;
      background-position: center;

      @media #{$x-large-down}{
        height: 20%;
        width: 20%;
      }
      @media #{$large-down}{
        display: none;
      }
    }
  }

}
#fp_download {
  position: relative;

  h2 {
    margin-bottom:90px;
  }
  p {
    text-align: center;
    @include din2014light();
    @include fontSize(18px);
  }

  .downloadBtn {
    margin-top: 35px;
    @include shiftCenter();
    padding: 10px 20px ;
    height: 40px ;
    width: 140px;
  }

  .dt_image {
  }
  .m_image {
    max-width: 300px;
    margin: auto;
  }

  img {
    width: 100%;
    height:auto;
  }

  @media #{$medium-down}{

    .container {
      padding-top:6rem;
      position: relative;
      top:0;
      left:0;
      .corner {
        content:'';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 300px 200px 0 0;
        border-color: #99ccaa transparent transparent transparent;
        position:absolute;
        top:0;
        left:0;

        .corner-inner {
          content:'';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 300px 100px 0 0;
          border-color: #158085  transparent transparent transparent;
          position:absolute;
          top:-300px;
          left:0;
        }

      }
    }

    .img_manuals {
      float: left;
      width: 50%;
    }
    .text {
      float: right;
      width: 50%;
    }
  }
  @media #{$small-down} {
    .img_manuals  img{
      width: 100%;
      margin: 0;
      left: -20px;
      position: relative;
      @include box-shadow(rgba(0, 0, 0, 0.31) 2px 3px 20px 3px);

    }
    .text {
      //width: 100%;
      margin-bottom:20px;
      p {
        //display:none;
      }
      h2.section_header {
        margin-bottom: 0;
      }
      .button {
        width:100%;
        min-width:120px;
      }
    }
  }
  @media #{$xsmall-only} {
    .text {
      p {
        display: none;
      }
    }
  }
}
#fp_news {

    >.container {
        padding-top: 20px!important;
    }


    .banner_headline_container {
        position: absolute;
        top: 45%;
        left: 0;
        width: 100%;
        h2, h6 {
        color: #fff;
        text-align: center;

        }

    }
    
    img {
        transition: all 0.5s ease;
    }

    .background_img {
        height: auto;
        width: 98%;
        z-index: -1;
        top: 0;
        left: 0;
        margin-left: 10px;
    }

    .mask {
        transition: all 0.5s ease;
        &.focus {
        filter: brightness(.5);
        -webkit-filter: brightness(.5);
        }
    }

    .fb-post {
        position: relative;
        //padding-bottom: 56.25%;
        //padding-top: 35px;

        height: 100%;
        overflow: hidden;

        @media #{$small-down} {
        padding-left: 30px;
        }
    }
    .match_h_fb {
        overflow: hidden;
    }

    .facebook_wrap {
        position: relative;
        overflow: hidden;
        display: table-cell;
        vertical-align: middle;
        height: 100%;
        a {
        color: black;
        text-decoration: none;
        }
        .post_img {
        width: 100%;
        height: auto;
        }
        .post_head {
        width: 100%;
        min-height: 60px;
        position: absolute;
        top: 0;
        padding: 3rem;
        background-color: rgba(255, 255, 255, 0.5);

        .icon {
            position: absolute;
            top: 30px;
            left: 30px;
            height: 45px;
            width: 45px;
        }
        h3 {
            padding-left: 55px;
        }
        p {
            padding-left: 55px;
            padding-top: 15px;

            strong {
            @include din2014bold;
            }
        }
        }

        .post_text {
            position: absolute;
            min-height: 60px;
            width: 100%;
            bottom: 0;
            background-color: rgba(255, 255, 255, 0.5);
            padding: 3rem;
            display: none;
        }
    }

    .carousel_container{

        .row {
            margin-left: -10px;
            margin-right: -10px;

            .slick-slide {
    
                .banner_slide{
        
                    img{
                        filter: brightness(0.5);
                        -webkit-filter: brightness(0.5);
                    }

                    .banner_slide_image_container{
                        transition: all 0.5s ease;
                        filter: brightness(0.5);
                        -webkit-filter: brightness(0.5);
                        @include aspect-ratio(4, 3);
                        
                        .banner_slide_image{
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                    }

                    .banner_headline_container{
                        display: block;
                    }
        
                    &:hover{
                        img, .banner_slide_image_container{
                            filter: brightness(1);
                            -webkit-filter: brightness(1);
                        }
                        .banner_headline_container{
                            display: none;
                        }
                    }
                }
            }
        }        
    }

    .newsContainerLeft{
        @media #{$medium-up} {
            padding-right: 0px;
        }
    }
    .newsContainerRight{
        @media #{$medium-up} {
            padding-left: 0px;
        }
    }

    .newsImageContainer{
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        padding: 20px;

        @media #{$medium-up} {
            padding: 30px;
        }
        @media #{$large-up} {
            padding: 40px;
        }
        @media #{$x-large-up} {
            padding: 50px;
        } 

        .newsImageContainerTitle{
            position: relative;
            margin: 0;
            height: auto;
            width: auto;
        }

        .newsImageContainerText{
            color: #393e42;
            font-size: 21px;
            line-height: 25px;
            @include din2014bold;

            font-size: 16px;
            line-height: 20px;
            
            @media #{$medium-up} {
                font-size: 16px;
                line-height: 20px;
            }
            @media #{$large-up} {
                font-size: 21px;
                line-height: 25px;
            }
            @media #{$x-large-up} {
                font-size: 21px;
                line-height: 25px;
            }            
            
            &.newsImageContainerText1{
                @include din2014light;    
                margin-bottom: 26px;
            }
            &.newsImageContainerText2{
                margin-bottom: 6px;

                font-size: 16px;
                line-height: 20px;
                
                @media #{$medium-up} {
                    font-size: 20px;
                    line-height: 24px;
                }
                @media #{$large-up} {
                    font-size: 24px;
                    line-height: 30px;
                }
                @media #{$x-large-up} {
                    font-size: 36px;
                    line-height: 43px;
                }
            }
            &.newsImageContainerText3{
                @include din2014light; 
                margin-bottom: 6px;                
            }
            &.newsImageContainerText4{
                font-size: 50px;
                line-height: 60px;
                margin-bottom: 6px ;   
                
                @media #{$medium-up} {
                    font-size: 60px;
                    line-height: 70px;
                }
                @media #{$large-up} {
                    font-size: 80px;
                    line-height: 90px;
                }
                @media #{$x-large-up} {
                    font-size: 100px;
                    line-height: 120px;
                }
            }
            &.newsImageContainerText5{
                color: #f59322;
                margin-bottom: 20px;  

                font-size: 16px;
                line-height: 20px;
                
                @media #{$medium-up} {
                    font-size: 20px;
                    line-height: 24px;
                }
                @media #{$large-up} {
                    font-size: 24px;
                    line-height: 30px;
                }
                @media #{$x-large-up} {
                    font-size: 36px;
                    line-height: 43px;
                }
            }
            &.newsImageContainerText6{
                @include din2014light; 
            }
        }

    }
}


