.cc_logo {
  display:none!important;
}

.cc_banner-wrapper{

    .cc_container{
        width:50%;
        padding-bottom:20px;
        
        @media #{$small-down}{
            width:100%;
            right: 0;
        }

        .cc_message{
            @include fontSize(16px);
        }

        .cc_btn.cc_btn_accept_all{
            @extend .primaryButton;
            color:white;
            bottom:-5px;
            left:0;
            letter-spacing: 0.2rem;
            padding: 0 10px;
            @include fontSize(13px);
            line-height: 50px;
            margin-top: 10px;
        }
    }
}