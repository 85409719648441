//#product_single_wrapper {
$accessory_gradient: linear-gradient(to top, lightgrey 0%, lightgrey 20%, white 100%);
$table_text_light: #6d6e71;

.product_single_header,
.product_single_overview,
.product_single_detail,
.media_video {
  .container {
    padding-top: 90px;
    padding-left: 150px;
    padding-right: 150px;

    @media #{$small-down} {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}
.product_single_header {
  .container {
    padding: 0
  }
}
.product_single_overview {

  h1 {
    font-size: $sectionHeaderLgFntSize;
    //@include headerStyling();

    .ps_ov_header_sm {
      @include din2014light;
      color: #818282;
      font-size: 18px;
    }

    &+ hr.subheader_hr {
      margin: 15px 0 20px 0;
      width : 30px;
      border-bottom: 2px black solid;
    }
  }
  .container {
    //min-height: 700px;
    background-color: $primaryColor9;

    .overview_img {
      vertical-align: middle;
      //padding: 30px 0;
      img {
        height: auto;
        margin:auto;
        max-height: 900px;
        max-width: 100%;
      }
      @media #{$large-up} {
        //min-height: 600px;
      }
    }
    .mob_img {
      width:50%;
      margin:auto;
      margin-bottom: 20px;
      img {
        width:100%;
        height:auto;
      }
    }

    table.info-table {
      color: $textLight2;
      margin-bottom: 100px;
      font-size: 14px;
      width: 100%;
      tr {
        &:nth-child(odd) {
          background-color: $tableColor-odd-light;
        }
        &:nth-child(even) {
          background-color: $tableColor-even;
        }

        td {
          padding: 5px;
        }
      }
    }
    .btn_spacer {
      display: none;
      @media #{$small-down} {
        display: block;
        height: 50px;
      }
    }
    .btn_container {
      position: absolute;
      bottom: 0;
      right: 0;

      .button {
        font-size: 16px;
        margin: 10px 10px;
        max-width: 45%;
        &:not(.solid_outline) {
          height: 40px;
          padding-top:8px;
        }
        &.solid_outline {
          color:$primaryDarkColor3;

          &:hover {
            color: $primaryDarkColor3;
          }
        }
      }
    }
  }
}
.product_single_detail {
  h2 {
    font-size: $sectionHeaderLgFntSize !important;
    text-align: left;
    text-transform: uppercase;

    //@include headerStyleSmall;
    //@include headerStyling();

    .header_sm{
      text-transform: none;
    }

  }
  h1.headline {
    margin-bottom: 50px;
  }
  & > .container {
    background-color: white !important;

    .info_tabs {
      margin-bottom: 40px;
      ul {
        list-style: none;
        text-align: center;

        li {
          @include din2014light;
          font-size: 18px;
          display: inline-block;
          border-radius: 10px;
          padding: 0 10px;
          color: $table_text_light;
          cursor: pointer;

          &.active {
            @include gradient1();
            color: white;
            @include din2014bold;
          }
        }
      }
    }

    .info_panel_container {
      //margin-top: 50px;
      .info_panel {
        display: none;

        &.active {
          display: block;
        }

        &#tech_data {

          table {
            width: 100%;
            font-size: 14px;
          }
          tr.info_section {
            border-bottom: white 25px solid;

            > td:first-child {
              @include din2014bold;
              font-size: 15px;
              text-align: center;
              color: white;
              @include gradient1();
              width: 16.5%;
            }
            > td:last-child {
              width: 83.5%;
            }

            table {
              @include din2014light;
              color: $table_text_light;
              width: 100%;

              tr {
                &.odd {
                  background-color: $tableColor-odd;
                }
                &.even {
                  background-color: white;
                }
                > td {
                  padding: 3px 25px 3px 25px;

                  &:first-child {
                    width: 30%;
                  }
                  &:last-child {
                    width: 70%;
                  }
                }
              }
            }
          }
        }

        &#tools {
          @extend #tech_data;

          table {
            tr.info_section {
              > td:first-child { /* title col */
                //width: 10%;
              }
              > td:last-child { /* unit size */
                width: 27%;
                text-align: center;
                background-color: $tableColor-odd;
              }

              table {
                tr {
                  > td {
                    padding: 5px;
                    text-align: center;

                    &:first-child {
                      width: 50%;
                    }
                    &:last-child {
                      width: 50%;
                    }
                  }
                }
              }
            }
          }

          .app-grid {
            width: 100%;
            transition: all 0.5s ease;
            //margin-top: 40px;
            &.fadein {
              opacity:0;
            }

            .grid-sizer { width: 25%; }
            .app-grid-item {
              box-sizing: border-box;
              position: relative;
              background-color: #f0eef3;
              border: 10px solid white;
              //height: 100px;
              float: left;

              &.app-grid-item--width1 {
                width: 25%;
                @media #{$large-down} {
                  width: 50%;

                }
                h3 {
                font-size: 26px;
                }
              }
              &.app-grid-item--width2 {
                width: 40%;
                @media #{$medium-up} {
                  h3{

                  }
                }
                @media #{$large-up} {
                  h3 {

                  }
                }
              }
              &.app-grid-item--width3 {
                width: 60%;
                @media #{$medium-up} {
                  h3{

                  }
                }
                @media #{$large-up} {
                  h3 {

                  }
                }
              }

              &.app-grid-item--height1 {
                //height: 300px;
              }
              &.app-grid-item--height2 {
                height: 430px;
              }
              &.app-grid-item--height3 { }

              img {
                position: relative;
                width: auto;
                max-width: 100%;
                height: auto;
                max-height: 430px;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                padding: 15px;
                box-sizing: border-box;
              }

              .item-overlay {
                position: absolute;
                top: 15px;
                bottom: 15px;
                left: 15px;
                right: 15px;
                opacity: 0;
                transition: all ease 1s;
                //filter: contrast(210%) brightness(80%);
                //-webkit-filter: contrast(210%) brightness(80%);
                background-color: rgba(0,0,0, 0.6);

                h3 {
                  color: white;
                  text-align: center;
                  position: relative;
                  top: 50%;
                  transform: translateY(-50%);

                  span.tool_thick {
                    @include din2014bold;
                  }
                  span.tool_thin {
                    @include din2014extralight;
                  }
                }
              }
              &.focus {
                .item-overlay {
                  opacity: 1;
                }
              }
            }
          }

        }

        &#application {
          @include din2014light;
          font-size: 20px;
          .app_element {

            //background-color: $primaryColor9;
            margin-bottom: 25px;
            //padding:5px;

            .feature_icon {

              text-align: center;

              img {
                position: relative;
                height: 100px;
                width: 100px;
              }
            }
            .feature_tag {
              text-align: center;
              padding: 0.625rem 0 0 0;
              height: 50px;
            }
          }
        }

        &#frames {
          @extend #tools;
          tr:first-child {
            color:white;
            @include gradient1();
          }
          td, th{
            text-align: center;
            padding:10px;
          }
          td.even {
            background-color: $tableColor-even;
          }
          td.odd {
            background-color: $tableColor-odd;
          }
          td.extra {
            background-color: $tableColor-odd-light;
          }
          img {
            width: auto;
            height: auto;

            max-width: 100%;
          }


          #frame-sizes {
            margin-top: 60px;
            .frame_size {
              width: 100%;

              max-width:100%;
              display:flex;
              &.front{
                align-items: flex-end;
              }
              &.top {
                align-items: flex-end;
                margin-bottom:50px;
              }
              &.side {
                align-items: flex-end;
                margin-bottom:50px;
              }
              >div {
                position: relative;
                display:inline-block;
              }
              .frame_front {
                margin-bottom:20px;
              }
              .frame_top { 
                margin-top:20px;
              }
              .frame_side { 
                margin-top:20px;
              }
              .size_label{
                position: absolute;

                &.label_top{
                  top: -10px;
                  width: 100%;
                  text-align: center;
                  &:before {
                    content: "";
                    width: 100%;
                    height: 5px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border: 1px #13988a solid;
                    border-bottom: 0;
                  }
                  span {
                    position: relative;
                    text-align: center;
                    top: -20px;
                    color: #13988a;
                    @include din2014;
                  }
                }
                &.label_side{
                  top:0;
                  height: 100%;
                  &:before{
                    content: "";
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: -10px;
                    width:5px;
                    border: 1px #13988a solid;
                    border-right:0;
                  }
                  span {
                    display: inline-block;
                    -webkit-transform: rotate(-90deg);
                    -moz-transform: rotate(-90deg);
                    -ms-transform: rotate(-90deg);
                    -o-transform: rotate(-90deg);
                    transform: rotate(-90deg);
                    position: relative;
                    text-align: center;
                    left: -46px;
                    top: 50%;
                    color: #13988a;
                    @include din2014;
                  }
                }
              }
              .size_main_label{
                height:25px;
                @include gradient1;
                text-align: center;
                vertical-align: middle;
                span {
                  text-align: center;
                  @include din2014bold;
                  color: white;
                  font-size: 14px;
                  line-height: 1.6;
                }
              }
            }
            // initial/default
            .frame_size {
              >div {
               margin-right:75px;
              }
            }

            // override margins based on number of entries
            &[data-frame-count="6"] {
              .frame_size {
                >div {
                  margin-right:54px;
                  &:last-child {
                    margin-right:0
                  }
                }
              }
            }
            &[data-frame-count="5"] {}
            &[data-frame-count="4"] {}
            &[data-frame-count="3"] {
              .frame_size {
                >div {
                  margin-left:75px;
                  margin-right:75px;
                }
              }
            }
            &[data-frame-count="2"] {
              .frame_size.front {
                img {
                  margin-right:40px;
                }
                &:first-child {
                  img{
                    margin-left: 35px;
                  }
                }
              }
            }
            &[data-frame-count="1"] {}
          }



        }
      }
    }
  }
  @media #{$small-down} {
    display: none;
  }
}
#product_single_media {
  .container {
    background-color: $primaryColor9;
  }
}
//}