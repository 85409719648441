$slick-dot-color: $primaryColor6 !default;
$slick-arrow-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-dot-size: 100px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

.carousel_container {
  //height: 690px;
  //max-width: $screen-xlg;
  margin: auto;
  //margin-bottom: -25px;
  padding-left: 0;
  padding-right: 0;
  //@include shiftCenter();
  //overflow: hidden;

  img {
    background-size: cover;
    height: 100%;
    overflow-x: hidden;
    width:100%;
  }

  h1, h2, h3, h4 {
    margin-top: 0;
  }

}
