

.brochure_header_container {
  padding: 0;

  .brochure_header_text_container {
    width: 40%;
    height:100%;
    padding: 8%;
    background-color: rgba(0, 0, 0, 0.725);
    color: white;
    h1 {
      text-align: left;
      @include din2014bold;
    }
    p {
    }
    .button {
      float: right;
    }
  }
}
#download_brochure {
  .container {
    background-color: #efefef;
  }
  .carousel_nav {
    float: right;
    ul {
      list-style: none;

      li {
        display: inline-block;
      }
    }
  }

}

#brochure_carousel {
  height: auto;
  width: 90%;
  //max-width: 1100px;
  margin: auto;

  .carousel_element {
    width: 80%;
    height: 780px;
    margin: 10px;
    display: inline-block;

    .carousel_pic {
      background-color: $secondaryColor2;
      max-height: 280px;
      height: 50%;
      overflow-x:hidden;
      text-align: center;

      img {
        width: auto;
      }
    }

    .carousel_info {
      background-color: white;
      height: 50%;
      //height:50%;
      position: relative;

      h2 {
        font-size: 20px;
      }
      p {
        font-size: 15px;
        color: $primaryDarkColor3;
      }

      a.button {
        position: absolute;
        bottom:100px;
        right:20px;
        height: 40px;
        max-width: 60%;
        padding: 9.5px 12.50px;
      }
    }

    &.active {
      .carousel_info {
        color:white;
      }
    }
  }
}

#download_manual {

  @include headerStyleSmall;
  .container {
    padding: 60px 100px;

    .manual_img {
      margin: 40px 0;
      width: 100%;
      //box-shadow: 5px 3px 11px 1px rgba(0, 0, 0, 0.2), 10px 9px 19px 1px rgba(0, 0, 0, 0.19);

      + .button {
        @include shiftCenter();
        background: none;
        background-color: $secondaryColor2;

        /*&:hover {
          &:after {

          }
        }*/

      }
      &:hover {
        box-shadow: 5px 3px 11px 1px rgba(0, 0, 0, 0.2), 10px 9px 19px 1px rgba(0, 0, 0, 0.19);
        .button:after {
          @include gradient1();
        }
      }
    }
  }
}

@media #{$small-down} {
  #brochure_carousel {
    min-height: 620px;
    height: initial;
    //width: 90%;
    //max-width: 1100px;
    //margin: auto;

    .carousel_element {
      width: 80%;
      max-height: 400px;
      margin: 10px;
      display: inline-block;

      .carousel_pic {
        background-color: $secondaryColor2;
        max-height: 200px;
        height: 50%;
        min-height: 1px;
        overflow-x:hidden;

        img {

        }
      }

      .carousel_info {
        background-color: white;
        max-height: 50%;
        height:50%;
        position: relative;
        h2 {
          font-size: 12px;
        }
        p {
          font-size: 10px;
          color: $primaryDarkColor3;
        }

        a.button {
          position: absolute;
          bottom:20px;
          right:0;
          height: 40px;
          min-width: 100%;
          padding: 9.50px;
        }
      }
    }
  }


  #download_manual {

    //@include headerStyleSmall;
    .container {
      //padding: 60px 100px;

      .row {
        &> div {
          padding-bottom:50px;
        }
      }

      .manual_img {
        margin: 20px 0;
        width: 100%;
        box-shadow: 5px 3px 11px 1px rgba(0, 0, 0, 0.2), 10px 9px 19px 1px rgba(0, 0, 0, 0.19);

        + .button {
          //@include shiftCenter();
          width: 100%;
          background: none;
          background-color: $secondaryColor2;


          &:hover {
            &:after {
              @include gradient1();
            }
          }

        }
      }
    }
  }


}