.prlx_section {
  /* --- calculate height --- set with js*/
  height:410px;
  .container {
    height:410px;
    overflow: hidden;
    padding:0;

    & > *{
      height:200%;
      width:auto;
      top:-100%;
      position: relative;
    }
  }
  @media #{$medium-down} {
    max-height:250px;
    .container {
      max-height:250px;
    }
  }
}

.brochure_header_container {
  overflow: hidden;
  height:365px;
  div.prlx_img {
    position:relative;
    height:200%;
    width:100%;
    top:-230%;
    z-index: -1;
    background-size: cover;
  }

  .btn {

    width:140px;
    height:40px;
    padding: 10px 12px 12px;
    letter-spacing: 3px;
  }
}